import React from 'react';
import '../../App.css';
import AboutBody from '../AboutBody'
import Footer from '../Footer';

function About () {
  return (
    <>
      <AboutBody/>
      <Footer/>
    </>
  )
}

export default About;
