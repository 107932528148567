import React from 'react';
import '../../App.css';
import ContactBody from '../ContactBody'
import Footer from '../Footer';

function Contact () {
  return (
    <>
      <ContactBody/>
      <Footer/>
    </>
  )
}

export default Contact;
